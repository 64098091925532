import React from 'react';
import { bool, func, node, number } from 'prop-types';
import classNames from 'classnames';
import { IoIosArrowDown } from 'react-icons/io';
import { IoIosArrowUp } from 'react-icons/io';

import css from './PopupOpenerButton.module.css';

const PopupOpenerButton = props => {
  const { isSelected, labelMaxWidth, toggleOpen, children, isOpen } = props;
  const labelStyles = isSelected ? css.labelSelected : css.label;
  const labelMaxWidthMaybe = labelMaxWidth ? { maxWidth: `${labelMaxWidth}px` } : {};
  const labelMaxWidthStyles = labelMaxWidth ? css.labelEllipsis : null;
  const arrow = isOpen ? (
    <IoIosArrowUp className={css.arrow} />
  ) : (
    <IoIosArrowDown className={css.arrow} />
  );
  return (
    <button
      className={classNames(labelStyles, labelMaxWidthStyles)}
      style={labelMaxWidthMaybe}
      onClick={() => toggleOpen()}
    >
      {children}
      {arrow}
    </button>
  );
};

PopupOpenerButton.defaultProps = {
  isSelected: false,
  labelMaxWidth: null,
};

PopupOpenerButton.propTypes = {
  isSelected: bool,
  toggleOpen: func.isRequired,
  children: node.isRequired,
  labelMaxWidth: number,
};

export default PopupOpenerButton;
